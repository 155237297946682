import type { HandleClientError } from '@sveltejs/kit';
import { dev } from '$app/environment';
import { customerEnvironment } from '$lib/environment';
import { init as SentryInit, captureException } from '@sentry/svelte';
import { BrowserTracing } from '@sentry/tracing';

if (!dev) {
  SentryInit({
    dsn: 'https://c4d16b1991aa40cdbee0df15c6117c88@logging.darwin-cloud.com/7',
    release: __version__,
    environment: customerEnvironment,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

export const handleError: HandleClientError = ({ error }) => {
  console.error(error);

  if (!dev) {
    captureException(error);
  }
};
